import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import users from '../backend/users';
import LogoutButton from '../components/LogoutButton';
import HomeButton from '../components/HomeButton';


function Isaac() {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const userUUID = localStorage.getItem('authentication');
        const targetUser = users.find(user => {
            return user.uuid === userUUID
        })
        if (targetUser) {
            if (targetUser.username === 'isaac') {
                setAuthenticated(true);
            } else {
                setAuthenticated(false);
            }
        } else {
            setAuthenticated(false);
        }
    })
    if (authenticated) {
        return (
            <>
                <BrowserView className="isaacBackground">
                    <div className="scrollingParent">
                        <div className="horizontalOrganizer">
                            <HomeButton />
                            <LogoutButton />
                        </div>
                        <div className="personalBox">
                            <h1>Isaac</h1>
                            Yow. Bing bong. Issac. I had the summer of my life turning this pandemic into a bandemic
                            with you. It&apos;s been super amazing to see the full depths of what an amazing person that
                            you are living with you, and to learn about all of the crazy experiences that you&apos;ve
                            had up until now that have shaped the person that you are to this day. Having you around
                            during probably some of the hardest four months of my life has meant the world to me.
                            I&apos;ve super super super appreciated all of the adventures that we&apos;ve been able to
                            have, and am excited for all of the adventures that lie ahead.
                            <br /> <br />

                            I was thinking about it last night, and I thought to myself that quite possibly, if I&apos;m
                            the narrator in my world, you might just have been my Tyler Durden this summer. Just there
                            to introduce a little bit of chaos, a little bit of fun, be a bit cooler than me, and also
                            push me to do some stuff outside of my comfort zone. Although I really do hope that you&apos;re
                            not just an imaginary friend that I made up in my mind, because that would be a bit tough.

                            <br /> <br />

                            I&apos;m sorry that our housing fates didn&apos;t find themselves in the same place for the
                            fall, but I&apos;m sure that they&apos;ll inevitably cross again. It was super awesome to
                            have you as a roommate, and I am forever envious of Sam that he has the privilege to live
                            with you for the next two years. I wish you guys nothing but the best. Nonetheless, I&apos;m
                            still super excited actually be on the ski team with you this year, and am looking forward
                            to everything that the upcoming season will bring. I feel super confident in your abilities
                            to lead the team alongside Mykal this year, you&apos;ll be awesome.

                            <br /> <br />
                            Anyways, here&apos;s to a successful summer and a budding friendship. You, alongside the
                            other roommates showed me a life of fun that I honestly have not experienced before. You
                            showed me how awesome it is to have a friend that is just super solid, does tons of
                            spontaneous fun stuff, and is unapologetically their own unique individual self.
                            <br /> <br />
                            Keep it in the house, <br />
                            Reese
                        </div>

                    </div>

                </BrowserView>
                <MobileView>
                    <h1>swag</h1>
                </MobileView>
            </>
        );
    }
    return (
        <>
            <BrowserView>
                <div className="darkParent">
                    <h1>Not Authenticated</h1>
                    <HomeButton />
                </div>
            </BrowserView>
            <MobileView>
                <h1>swag</h1>
            </MobileView>
        </>
    );
}

export default Isaac
