import React from 'react';
import '../stylesheets/general.css';

function handleLogout() {
    localStorage.clear();
    window.location.href = '/';
}

function LogoutButton() {
    return (
        <button onClick={handleLogout} className="logoutButton">
            logout
        </button>
    )
}

export default LogoutButton
