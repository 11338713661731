import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import users from '../backend/users';
import HomeButton from '../components/HomeButton';
import LogoutButton from '../components/LogoutButton';


function Anna() {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const userUUID = localStorage.getItem('authentication');
        const targetUser = users.find(user => {
            return user.uuid === userUUID
        })
        if (targetUser) {
            if (targetUser.username === 'anna') {
                setAuthenticated(true);
            } else {
                setAuthenticated(false);
            }
        } else {
            setAuthenticated(false);
        }
    })
    if (authenticated) {
        return (
            <>
                <BrowserView className="annaBackground">
                    <div className="scrollingParent">
                        <div className="horizontalOrganizer">
                            <HomeButton />
                            <LogoutButton />
                        </div>
                        <div className="personalBox">
                            <h1>Anna</h1>
                            Anna! It&apos;s been fun to witness you have the hottest of hot girl summers while I&apos;ve
                            had the sweatiest of engineering summers. It&apos;s also been super amazing to get to know
                            you way more than I had before. You are a person that cares so deeply for the other people
                            in your life, and I have a lot of respect for the way that you treat other people.
                            <br /> <br />
                            On a more superficial note, I&apos;ve also greatly appreciated sharing a kitchen with you
                            [haha]. It&apos;s been fun living with someone who appreciates a great restaurant, or who can
                            serve up a mean red curry. I also might like your knife more than I like you but I won&apos;t
                            indulge that.
                            <br /> <br />
                            I&apos;m sad that we won&apos;t be on the ski team together next year, but I&apos;m also
                            super excited to see the life that you&apos;re going to build for yourself in Squamish with
                            Anna, Austin, and all of the others. I&apos;m sure that it&apos;s going to be filled with
                            some amazing Squamish outdoor adventures and around-the-house everyday memories. Keep fighting for the things that are
                            important to you, and the people that value you. Also keep grinding hinge for that man
                            that will <strike>go to the end of the earth</strike> get covid for you.
                            <br /> <br />
                            Keep trusting the master plan.
                            <br />
                            - Reese
                        </div>

                    </div>

                </BrowserView>
                <MobileView>
                    <h1>swag</h1>
                </MobileView>
            </>
        );
    }
    return (
        <>
            <BrowserView>
                <div className="darkParent">
                    <h1>Not Authenticated</h1>
                    <HomeButton />
                </div>
            </BrowserView>
            <MobileView>
                <h1>swag</h1>
            </MobileView>
        </>
    );
}

export default Anna
