


class User {
    constructor(username, password, path, page, uuid) {
        this.username = username;
        this.password = password;
        this.path = path;
        this.page = page;
        this.uuid = uuid;
    }

    static flattenUsername(username) {
        const removedWhitespace = username.trim();
        return removedWhitespace.toLowerCase();
    }
}

export default User
