import React from 'react';
import { Link } from 'react-router-dom';
import '../stylesheets/general.css';

function HomeButton() {
    return (
        <Link to="/" className="homeButton">
            home
        </Link>
    )
}

export default HomeButton
