import React from 'react'
import "./App.css";
import Home from './pages/Home';
import { Routes, Route } from "react-router-dom";
import Basics from './pages/Basics';
import Login from './pages/Login';
import Isaac from './pages/Isaac';
import Anna from './pages/Anna';
import Rachel from './pages/Rachel';

function App() {
  return (
    <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/basics" element={<Basics />} />
        <Route path="/login" element={<Login />} />
        <Route path="/isaac" element={<Isaac />} />
        <Route path="/anna" element={<Anna />} />
        <Route path="/rachel" element={<Rachel />} />
    </Routes>
  );
}

export default App;
