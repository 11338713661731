import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import '../stylesheets/home.css';
import { Link } from 'react-router-dom';
import users from '../backend/users';

function Home() {
    const siteTitles = ['a food guide.', 'glacialFood', 'learn make food', 'reese\'s food guide'];
    const [title, setTitle] = useState(siteTitles[Math.floor(Math.random()*siteTitles.length)]);

    useEffect(() => {
        const currentUUID = localStorage.getItem('authentication');
        const targetUser = users.find(user => {
            return user.uuid === currentUUID
        })
        if (targetUser) {
            setTitle(`${targetUser.username}'s food guide`);
        }
    })

    return (
        <>
            <BrowserView>
                <div className="parent">
                    <div className="centerBoxHome">
                        <h1 className="headerTextHome">{title}</h1>
                        <div className="innerContainer">
                            <Link to="/" className="box1">
                                <h2 className="buttonText">recipes</h2>
                            </Link>
                            <Link to="/" className="box2">
                                <h2 className="buttonText">about</h2>
                            </Link>
                            <Link to="/basics" className="box3">
                                <h2 className="buttonText">basics</h2>
                            </Link>
                            <Link to="/" className="box4">
                                <h2 className="buttonText">kitchen lists</h2>
                            </Link>
                            <Link to="/login" className="box5">
                                <h2 className="buttonText">?</h2>
                            </Link>
                        </div>
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <h1>Welcome!</h1>
            </MobileView>
        </>
    );
}

export default Home;
