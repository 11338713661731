import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import '../stylesheets/basics.css';
import HomeButton from '../components/HomeButton';

function Basics() {
    return (
        <>
            <BrowserView>
                <div className="parent">
                    <h1>hello</h1>
                    <HomeButton />
                </div>
            </BrowserView>
            <MobileView>
                <h1>see ya</h1>
            </MobileView>
        </>
    )
}

export default Basics;
