import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import '../stylesheets/login.css';
import HomeButton from '../components/HomeButton';
import users from '../backend/users';
import User from '../backend/User';



function Login() {
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        const currentUUID = localStorage.getItem('authentication');
        const targetUser = users.find(user => {
            return user.uuid === currentUUID
        })
        if (targetUser) {
            window.location.href = targetUser.path;
        }
    })

    function handleLogin(event) {
        event.preventDefault();
        const targetUser = users.find(user => {
            return User.flattenUsername(user.username) === User.flattenUsername(name)
        })
        if (targetUser) {
            if (targetUser.password === password) {
                localStorage.setItem('authentication', targetUser.uuid);
                window.location.href = targetUser.path;
            }
        }
        setName('');
        setPassword('');
    }

    return (
        <>
            <BrowserView>
                <div className="parent">
                    <HomeButton />
                    <form onSubmit={handleLogin}>
                        <div className="centerBox">

                            <label className="headerText" htmlFor="name">name</label>
                            <input className="nameField" type="text" id="name" onChange={(x) => setName(x.target.value)} value={name} />

                            <label className="headerText" htmlFor="password">password</label>
                            <input className="nameField" type="password" id="password" onChange={(x) => setPassword(x.target.value)} value={password} />
                        </div>
                       <button type="submit" className="submitButton">submit</button>
                    </form>
                </div>
            </BrowserView>
            <MobileView>
                <h1>hello</h1>
            </MobileView>
        </>
    )
}

export default Login
