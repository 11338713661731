import React, { useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import users from '../backend/users';
import HomeButton from '../components/HomeButton';
import LogoutButton from '../components/LogoutButton';


function Rachel() {
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        const userUUID = localStorage.getItem('authentication');
        const targetUser = users.find(user => {
            return user.uuid === userUUID
        })
        if (targetUser) {
            if (targetUser.username === 'rachel') {
                setAuthenticated(true);
            } else {
                setAuthenticated(false);
            }
        } else {
            setAuthenticated(false);
        }
    })
    if (authenticated) {
        return (
            <>
                <BrowserView className="rachelBackground">
                    <div className="scrollingParent">
                        <div className="horizontalOrganizer">
                            <HomeButton />
                            <LogoutButton />
                        </div>
                        <div className="personalBox">
                            <h1>Rachel</h1>
                            <i>Well, I guess we got some unpacking to do.</i>
                            <br/>
                            <br/>
                            From rebound gang to here eh? I guess I didn&apos;t actually expect to become friends with you because of how cheugy you seemed but I guess now that I very fortunate to have become such a close friend
                            with you at this point of my life. I feel like in a way that our friendship is the
                            duality of man. On one hand, it&apos;s filled with sarcastic humour that sometimes falls flat on
                            its face, and probably more of the time, it&apos;s filled with some of the most deep, psychologically
                            engaging, and emotionally aware conversations I&apos;ve ever had. You have a superpower of being able to discuss
                            yours and others emotions on a level that simply transcends the norm and blows nearly everyone else I&apos;ve ever known out of the water. As well,
                            I&apos;ve also appreciated deeply the spontaneous energy that maybe sometimes I don&apos;t anticipate,
                            especially with your willingness to rally and do stuff with little to no preparation. [Possibly just
                            middle-child-people-pleasing-hard-wiring, but I&apos;ll let it slide].
                            <br /> <br />
                            Nonetheless, I seriously do not think that I could have survived and borderline thrived as much this summer
                            without your presence. I very much hope that we can keep close friends
                            in a post-roommate era, as I don&apos;t think that I can properly articulate in words how much I have
                            valued having you as a friend. Anyways, I&apos;m beyond excited to actually be on the ski team with you
                            this year, so I guess my closing remarks are: don&apos;t have too much fun with your new roommates,
                            stay cheugy, and let me know if you ever need to unpack anything.
                            <br /> <br />
                            - Reese
                        </div>

                    </div>

                </BrowserView>
                <MobileView>
                    <h1>swag</h1>
                </MobileView>
            </>
        );
    }
    return (
        <>
            <BrowserView>
                <div className="darkParent">
                    <h1>Not Authenticated</h1>
                    <HomeButton />
                </div>
            </BrowserView>
            <MobileView>
                <h1>swag</h1>
            </MobileView>
        </>
    );
}

export default Rachel
