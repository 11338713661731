import User from './User';
import Isaac from '../pages/Isaac';
import React from 'react';
import Rachel from '../pages/Rachel';
import Anna from '../pages/Anna';

const users = [
    new User('isaac', 'fuckitweball', '/isaac', <Isaac />, 'ee4e0d7a-26bb-4e40-977a-64dcc9e3a127'),
    new User('rachel', 'letsunpackthat', '/rachel', <Rachel />, 'a70dce45-aaa2-4132-8323-7114cfea4ec2'),
    new User('anna', 'slideoffmychair', '/anna', <Anna />, '1900d2cd-2191-4f9a-bf96-47df57f8c96c')
]

export default users
